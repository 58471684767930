// Distance is used in the JSDoc comments
// eslint-disable-next-line no-unused-vars
import Distance from './Distance';
import Split from './Split';
import RACE_TYPE from './RACE_TYPE';

class Race {
  /**
   * @param {Object} arg
   * @param {string} arg.name Name of the new Race instance
   * @param {Distance} arg.distance Distance of the new Race instance
   * @param {string} arg.type Type of Race (ex. RACE_TYPE.TRACK)
   * @param {array} arg.landmarks An array of landmark objects with a `name` and
   * `distance` string
   */
  constructor({ name, distance, type = RACE_TYPE.TRACK, landmarks = [] }) {
    if (!name && !distance) {
      throw new TypeError(
        'Race constructor takes an object with `name` and `distance` values',
      );
    }
    this.name = name;
    this.distance = distance;
    this.type = type;
    this.landmarks = landmarks;
  }

  // time is either a number of seconds or it is a string representing a duration
  getSplitsForTime(time) {
    let intervals;
    switch (this.type) {
      case RACE_TYPE.TRACK:
        intervals = ['400m', '1000m'];
        break;
      case RACE_TYPE.ROAD:
        intervals = ['1km', '1 mile'];
        break;
      default:
        break;
    }
    let finishSplit;
    if (typeof time === 'number') {
      finishSplit = new Split({ distance: this.distance, seconds: time });
    } else {
      finishSplit = Split.fromString(`${this.distance.toString()} ${time}`);
    }
    return finishSplit.getIntermediateSplits(intervals, this.landmarks);
  }
}

export default Race;
