import DURATION_FORMAT from '../models/DURATION_FORMAT';

const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;

/**
 * Utilities to convert strings to seconds and seconds to formatted strings
 */

export function getHoursMinutesSecondsFromSeconds(secs, precision = 1) {
  let seconds = parseFloat(secs.toFixed(precision));
  const hours = Math.floor(seconds / SECONDS_PER_HOUR);
  seconds -= hours * SECONDS_PER_HOUR;
  const minutes = Math.floor(seconds / SECONDS_PER_MINUTE);
  seconds -= minutes * SECONDS_PER_MINUTE;
  seconds = seconds.toFixed(precision);
  return [hours, minutes, seconds];
}

/**
 * Takes a given number of seconds and returns a stringified version of the
 * elapsed time.
 *
 * @param {number} sec - the number of seconds to format nicely
 * @param {object} options - optional options object to change the appearance of
 *                           the returned string
 * @returns {string}
 */
export function formattedDurationFromSeconds(
  secs,
  { precision = 1, format = DURATION_FORMAT.HOURS } = {},
) {
  if (format === DURATION_FORMAT.LAP && secs < 100) {
    return `${secs.toFixed(precision)}s`;
  }
  const [hours, minutes, seconds] = getHoursMinutesSecondsFromSeconds(
    secs,
    precision,
  );

  if (format === DURATION_FORMAT.HMS) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  let formattedHoursLength = 0;
  let formattedMinutesLength = 0;
  // TODO: improve this to detect the number of decimal places in `secs` and
  // use that for the default value of `precision`
  const formattedSecondsLength = precision ? precision + 3 : 2;

  switch (format) {
    case DURATION_FORMAT.MINUTES:
      formattedMinutesLength = 1;
      break;
    case DURATION_FORMAT.PAD_MINUTES:
      formattedMinutesLength = 2;
      break;
    case DURATION_FORMAT.HOURS:
      formattedHoursLength = 1;
      formattedMinutesLength = 2;
      break;
    case DURATION_FORMAT.PAD_HOURS:
      formattedHoursLength = 2;
      formattedMinutesLength = 2;
      break;
  }

  if (hours) {
    formattedMinutesLength = 2;
  }

  const hString = hours.toString().padStart(formattedHoursLength, '0');
  const mString = minutes.toString().padStart(formattedMinutesLength, '0');
  const sString = seconds.toString().padStart(formattedSecondsLength, '0');

  if (!formattedHoursLength && !hours) {
    return `${mString}:${sString}`;
  }
  return `${hString}:${mString}:${sString}`;
}

/**
 * Takes in a properly formatted duration string (ex. hh:mm:ss.s or ss.ssss) and
 * returns the number of seconds for that string
 *
 * @param {string} durationString
 *
 * @returns {number} The number of seconds in the durationString
 */
export function formattedDurationToSeconds(durationString) {
  const [seconds = 0, minutes = 0, hours = 0] = durationString
    .split(':')
    .reverse();

  return (
    parseInt(hours, 10) * SECONDS_PER_HOUR +
    parseInt(minutes, 10) * SECONDS_PER_MINUTE +
    parseFloat(seconds)
  );
}

/**
 * Takes in a number or string like '400' or 12345.6 and treats it as though it
 * was a duration that was missing colons and returns a nicely formatted version
 *
 * example: parseUnformattedDurationFromString(1234) returns "12:34.0"
 *
 * @export
 * @param {string|number} input
 */
export function parseUnformattedDurationFromString(input) {
  let invalidInput = true;
  if (
    arguments.length > 0 &&
    (typeof input === 'string' || typeof input === 'number')
  ) {
    invalidInput = false;
  }
  if (invalidInput) {
    throw new TypeError(`${inputString} must be a string or number`);
  }
  const inputString = typeof input === 'number' ? `${input}` : input;
  const regex = new RegExp(/(?:^|\s)(\d+)(\.\d+)?(?=(?:$|\s|,))/);
  const match = inputString.match(regex);
  if (!match) {
    // throw new Error(
    //   `${inputString} does not appear to contain a valid duration`,
    // );
    return false;
  }
  const beforeDecimal = match[1];
  const afterDecimal = match[2];
  const digits = beforeDecimal.split('');
  while (digits.length < 5) {
    digits.unshift('0');
  }
  const seconds = parseInt(digits.splice(-2, 2).join(''));
  const minutes = parseInt(digits.splice(-2, 2).join(''));
  const hours = parseInt(digits.join(''));
  let totalSeconds = hours * 3600 + minutes * 60 + seconds;
  if (afterDecimal) {
    totalSeconds += parseFloat(afterDecimal);
  }
  return formattedDurationFromSeconds(totalSeconds, {
    format: DURATION_FORMAT.MINUTES,
    precision: afterDecimal ? afterDecimal.length - 1 : 1,
  });
}

/**
 * Attempts to parse a properly formatted duration from an input string.
 *
 * Given 'the following, 1:44.39 is a duration' returns '1:44.39'
 *
 * If the `strict` flag is `false` it will also treat numbers as though they
 * are in the format HHMMSS.SS, so it would return '1:44.39' when passed in
 * '144.39'
 *
 * @param {string} inputString - String to pull a formatted duration from
 * @param {boolean} strict=true - When false, fall back to treating numbers as
 * durations without colon separators
 * @returns {string} The duration found in the inputString, converted to a consistent format
 */
export function parseDurationFromString(inputString, strict = true) {
  if (arguments.length === 0 || typeof inputString !== 'string') {
    throw new TypeError(`${inputString} must be a string`);
  }

  const regexps = [
    // matches hours, minutes, seconds (ex. 1:11:11 or 12:12:12.12)
    new RegExp(/(\d+:\d{2}:\d{2}(?:\.\d+)?)(?=(?:$|\s|,))/),
    // matches minutes, seconds (ex. 1:11.1 or 12:12)
    new RegExp(/(\d+:\d{2}(?:\.\d+)?)(?=(?:$|\s|,))/),
    // matches seconds with a leading colon (ex. :45)
    new RegExp(/:(\d{2}(?:\.\d+)?)(?=(?:$|\s|,))/),
    // matches seconds with a trailing `s` (ex. 45.5s), but returns the match
    // without the trailing s
    new RegExp(/^(\d+(?:\.\d+)?)(?:s)(?=(?:$|\s|,))/),
  ];

  let duration = null;
  // remove any potential extra spaces from the input string
  const inputWords = inputString.split(' ').filter((word) => word.length);
  for (const word of inputWords) {
    for (const regexp of regexps) {
      if (word.match(regexp)) {
        duration = word.match(regexp)[1];
        const durationAsSeconds = formattedDurationToSeconds(duration);
        // get the number of decimal places in durationAsSeconds
        const [, afterDecimal] = `${durationAsSeconds}`.split('.');
        duration = formattedDurationFromSeconds(durationAsSeconds, {
          format: DURATION_FORMAT.MINUTES,
          precision: afterDecimal ? afterDecimal.length : 1,
        });
        break;
      }
    }
  }
  if (!strict && !duration) {
    for (const word of inputWords) {
      duration = parseUnformattedDurationFromString(word);
      if (duration) {
        break;
      }
    }
  }
  if (!duration) {
    throw new Error(
      `${inputString} does not appear to contain a valid duration`,
    );
  }
  return duration;
}
