import React from 'react'
import {
  formattedDurationToSeconds,
  parseUnformattedDurationFromString,
} from 'lydiard/utilities/duration-utilities'
import Distance from 'lydiard/models/Distance'
import DURATION_FORMAT from 'lydiard/models/DURATION_FORMAT'
import Split from 'lydiard/models/Split'

import PredictedFinishButton from './PredictedFinishButton'
import DEFAULT_RACES from '../DEFAULT_RACES'

const App = () => {
  const [userEnteredTime, setUserEnteredTime] = React.useState('')
  const [userEnteredDistance, setUserEnteredDistance] = React.useState('')
  const [userEnteredUnits, setUserEnteredUnits] = React.useState('meters')
  const [focusedRace, setFocusedRace] = React.useState(null)
  const [focusedRaceSplits, setFocusedRaceSplits] = React.useState([])

  const [seconds, setSeconds] = React.useState(null)
  const [formattedTime, setFormattedTime] = React.useState(null)
  const [distance, setDistance] = React.useState(null)
  const [userEnteredSplits, setUserEnteredSplits] = React.useState([])

  const calculate = e => {
    e.preventDefault()
    try {
      const userEnteredSplit = new Split({
        distance,
        seconds,
      })
      setUserEnteredSplits([userEnteredSplit])
      // TODO: restore user input history after enabling focused race with
      // intermediate splits: setSplits([userEnteredSplit,
      // ...splits].slice(0, 3))
      if (
        !!focusedRace &&
        userEnteredSplit.isLongerThan(focusedRace.distance)
      ) {
        setFocusedRace(null)
      }
      setUserEnteredTime('')
      setUserEnteredDistance('')
      document.activeElement.blur()
    } catch (e) {
      console.error(e) // eslint-disable-line
    }
  }

  const distanceUnitsKeyListener = e => {
    switch (e.key) {
      case 'm':
        e.preventDefault()
        setUserEnteredUnits('meters')
        break
      case 'k':
        e.preventDefault()
        setUserEnteredUnits('kilometers')
        break
      case 'M':
        e.preventDefault()
        setUserEnteredUnits('miles')
        break
      case 'l':
        e.preventDefault()
        setUserEnteredUnits('laps')
        break
      case 'y':
        e.preventDefault()
        setUserEnteredUnits('yards')
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    try {
      const parsedDuration = parseUnformattedDurationFromString(userEnteredTime)
      setFormattedTime(parsedDuration)
      setSeconds(formattedDurationToSeconds(parsedDuration))
    } catch (e) {
      setFormattedTime(null)
      setSeconds(null)
    }
  }, [userEnteredTime])

  React.useEffect(() => {
    try {
      setDistance(
        Distance.fromString(`${userEnteredDistance} ${userEnteredUnits}`),
      )
    } catch (e) {
      setDistance(null)
    }
  }, [userEnteredDistance, userEnteredUnits])

  React.useEffect(() => {
    if (!focusedRace) {
      setFocusedRaceSplits([])
    } else {
      const finishTime = userEnteredSplits[0].predictFinish(
        focusedRace.distance,
      )
      setFocusedRaceSplits(focusedRace.getSplitsForTime(finishTime))
    }
  }, [focusedRace, userEnteredSplits])

  return (
    <div className="mx-auto max-w-md px-4">
      <form className="py-4" onSubmit={calculate}>
        <div className="flex flex-col space-y-4">
          <div>
            <label
              htmlFor="distance-input"
              className="block text-sm font-medium text-slate-700"
            >
              Distance
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                autoFocus
                id="distance-input"
                type="number"
                step="any"
                inputMode="decimal"
                value={userEnteredDistance}
                min={1}
                required
                onChange={e => {
                  setUserEnteredDistance(e.target.value)
                }}
                onKeyDown={distanceUnitsKeyListener}
                className="block w-full rounded-md border-slate-300 pr-24 text-lg focus:border-indigo-500 focus:ring-indigo-500"
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <label htmlFor="distance-units-select" className="sr-only">
                  Distance Units
                </label>
                <select
                  id="distance-units-select"
                  className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-sm text-slate-500 [direction:rtl] [text-align-last:right] focus:border-indigo-500 focus:ring-indigo-500"
                  value={userEnteredUnits}
                  onChange={e => {
                    setUserEnteredUnits(e.target.value)
                  }}
                >
                  <option className="[direction:ltr]" value="meters">
                    meters (m)
                  </option>
                  <option className="[direction:ltr]" value="kilometers">
                    kilometers (k)
                  </option>
                  <option className="[direction:ltr]" value="laps">
                    laps (l)
                  </option>
                  <option className="[direction:ltr]" value="miles">
                    miles (M)
                  </option>
                  <option className="[direction:ltr]" value="yards">
                    yards (y)
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div>
            <label
              htmlFor="time-input"
              className="block text-sm font-medium text-slate-700"
            >
              Time
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                id="time-input"
                type="number"
                step="any"
                inputMode="decimal"
                required
                value={userEnteredTime}
                min={0.01}
                onChange={e => {
                  setUserEnteredTime(e.target.value)
                }}
                className="block w-full rounded-md border-slate-300 pr-12 text-lg focus:border-indigo-500 focus:ring-indigo-500"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                <span className="mr-3 rounded border border-slate-100 px-2 py-1 text-xs font-bold text-slate-500 shadow-sm shadow-slate-200/10">
                  {formattedTime ? formattedTime : '0:00.0'}
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <p data-testid="parsed-split">
              <span className="font-bold">
                {distance ? distance.toString(false) : '---'}
              </span>
              <span> in </span>
              <span className="font-bold">
                {formattedTime ? formattedTime : '---'}
              </span>
            </p>
            <input
              type="submit"
              className="rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              value="Calculate"
            />
          </div>
        </div>
      </form>
      {userEnteredSplits.map((split, i) => {
        return (
          <article
            key={i}
            className="my-4 overflow-hidden rounded-lg border bg-white shadow-xl drop-shadow"
          >
            <header className="bg-slate-100 p-2 text-center">
              <div className="flex justify-evenly">
                <h3 className="text-lg font-bold">
                  {split.toString({
                    shortDistanceFormat: false,
                    format: DURATION_FORMAT.LAP,
                  })}
                </h3>
              </div>
              <p className="text-sm text-slate-700">
                {split.pacePerLap()} • {split.pacePerMile()} •{' '}
                {split.pacePerKilometer()}
              </p>
            </header>
            <div className="px-2 pb-2">
              <div className="bg-white/80 py-1 backdrop-blur-sm">
                <h3 className="text-sm font-bold uppercase text-slate-500">
                  Predicted finishes
                </h3>
              </div>
              {!!focusedRace ? (
                <>
                  <PredictedFinishButton
                    race={focusedRace}
                    split={split}
                    onClick={() => {
                      setFocusedRace(null)
                    }}
                    focused={true}
                  />
                  <ul className="hidden">
                    {focusedRaceSplits.map(split => {
                      const classes = !split.isLongerThan(userEnteredSplits[0])
                        ? 'text-slate-400 italic'
                        : ''
                      return <li className={classes}>{split.toString()}</li>
                    })}
                  </ul>
                </>
              ) : (
                <>
                  <ul className="space-y-2">
                    {DEFAULT_RACES.filter(
                      race => !race.distance.isShorterThan(split.distance),
                    ).map((race, i) => {
                      return (
                        <li key={i}>
                          <PredictedFinishButton
                            race={race}
                            split={split}
                            onClick={() => {
                              setFocusedRace(race)
                            }}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </>
              )}
            </div>
          </article>
        )
      })}
    </div>
  )
}

export default App
