/**
 * PAD_HOURS: Always show two digits for hours (ex. 00:01:09.4 or 01:23:45.6)
 * HOURS: Always show at least one digit for hours (ex. 0:01:09.4)
 * PAD_MINUTES: Always show two digits for minutes (ex. 01:09.4 or 00:09)
 * MINUTES: Always show at least one digit for minutes (ex. 1:09.4 or 0:09)
 * LAP: Focus on the seconds. Don't show minutes if < 100 seconds. (ex. 9.4s or 9s or 67.1 or 1:41.1)
 * HMS: 1h 5m 23.2s
 */
const DURATION_FORMAT = Object.freeze({
  PAD_HOURS: 'PAD_HOURS',
  HOURS: 'HOURS',
  PAD_MINUTES: 'PAD_MINUTES',
  MINUTES: 'MINUTES',
  LAP: 'LAP',
  HMS: 'HMS',
});

export default DURATION_FORMAT;
