import { pluralizeString } from '../utilities/string-utilities';
import DISTANCE_UNIT from './DISTANCE_UNIT';

/**
 * @param {number} length - The number of units in length
 * @param {DISTANCE_UNIT} unit - A valid DISTANCE_UNIT
 * @class Distance
 */
class Distance {
  /**
   * @param {number} length - The number of units in length
   * @param {DISTANCE_UNIT} unit - A valid DISTANCE_UNIT
   * @param {string} name - An optional name for the distance, such as "Half
   * Marathon"
   */
  constructor(length = 400, unit = DISTANCE_UNIT.METER, name = null) {
    // TODO: should throw an error when unit is not valid
    // TODO: maybe allow for the constructor to accept a string and then drop
    // the `fromString` static method?
    this.length = length;
    this.unit = unit;
    this.name = name;
  }

  // Compares the input string to a complex regex that attempts to find a
  // distance and returns the result of running input.match() with the complex
  // regex
  static matchStringToDistanceRegex(input) {
    if (arguments.length === 0 || typeof input !== 'string') {
      throw new TypeError(`${input} must be a string`);
    }

    // build the regexp to match against
    const units = Object.values(DISTANCE_UNIT).reduce(
      (sum, unit) => [...sum, ...unit.spellings],
      [],
    );
    const unitsStrings = units.join('|');
    // The trailing (?:\\s|$)+ looks for either a whitespace or EOL after the
    // units of measure. Without it we'd match 100mm as 100m
    const unitsRegex = new RegExp(`([\\d.,]+)\\s*(${unitsStrings})(?:\\s|$)+`);
    const match = input.match(unitsRegex);

    if (match === null) {
      throw new Error(`${input} does not appear to contain a valid distance`);
    }
    return match;
  }

  // Find and return a distance string from an input string
  //
  // ex: parseDistanceStringFromString("I ran 1 mile in ten minutes") returns "1
  // mile"
  static parseDistanceStringFromString(input = '') {
    return this.matchStringToDistanceRegex(input)[0];
  }

  /**
   * Static method that attempts to parse a string and turn it into an instance
   * of a Distance object. Note that this method will find a distance in a
   * string even if the passed-in string does not *begin* with the distance. It
   * will also parse a positive distance if the distance string begins with a
   * `-`. See the test cases to get a full understanding of how to use this
   * function.
   *
   * @param {string} distanceString - String to parse into a Distance instance
   * @returns {Distance}
   */
  static fromString(distanceString) {
    const match = this.matchStringToDistanceRegex(distanceString);

    // find the DISTANCE_UNIT whose `spellings` prop contains the distance unit
    // matched by the regex.
    let unit = null;
    Object.values(DISTANCE_UNIT).forEach((distanceUnit) => {
      if (distanceUnit.spellings.includes(match[2])) {
        unit = distanceUnit;
      }
    });

    return new Distance(parseFloat(match[1]), unit);
  }

  /**
   *
   * @param {string|Distance} arg The thing to check
   * @returns {boolean} True if the arg is either a valid Distance instance or
   * is a string that can be parsed into a Distance instance
   */
  static isValidDistance(arg) {
    if (arg?.constructor === Distance) {
      return true;
    }
    try {
      Distance.fromString(arg);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Converts this instance to meters, rounded to two decimal places, mostly
   * just to prevent us from seeing stupid JS math errors like 1.5 * 1609.34
   * resulting in 2414.0099999999998
   */
  toMeters(precision = 2) {
    return parseFloat((this.length * this.unit.meters).toFixed(precision));
  }

  toKilometers(precision = 2) {
    return parseFloat(
      ((this.length * this.unit.meters) / 1000).toFixed(precision),
    );
  }

  toMiles(precision = 2) {
    if (this.unit === DISTANCE_UNIT.MILE) {
      return parseFloat(this.length.toFixed(precision));
    }
    return parseFloat(
      ((this.length * this.unit.meters) / DISTANCE_UNIT.MILE.meters).toFixed(
        precision,
      ),
    );
  }

  toYards(precision = 2) {
    if (this.unit === DISTANCE_UNIT.YARD) {
      return parseFloat(this.length.toFixed(precision));
    }
    return parseFloat((this.toMiles(precision) * 1760).toFixed(precision));
  }

  toLaps(precision = 2) {
    if (this.unit === DISTANCE_UNIT.LAP) {
      return parseFloat(this.length.toFixed(precision));
    }
    return parseFloat(
      ((this.length * this.unit.meters) / DISTANCE_UNIT.LAP.meters).toFixed(
        precision,
      ),
    );
  }

  /**
   * General purpose distance conversion method. Converts this instance to a
   * given DISTANCE_UNIT
   * @param {DISTANCE_UNIT} unit - the unit to convert to
   * @param {number} [precision=3] -
   */
  convertTo(unit, precision = 3) {
    switch (unit) {
      case DISTANCE_UNIT.MILE:
        return this.toMiles(precision);
      case DISTANCE_UNIT.KILOMETER:
        return this.toKilometers(precision);
      case DISTANCE_UNIT.YARD:
        return this.toYards(precision);
      case DISTANCE_UNIT.METER:
        return this.toMeters(precision);
      case DISTANCE_UNIT.LAP:
        return this.toLaps(precision);
      default:
        return this.toMeters(precision);
    }
  }

  toString(shortFormat = true) {
    if (shortFormat) {
      if (this.name) {
        return this.name;
      }
      return `${this.length}${this.unit.shortName}`;
    }
    const longDescription = pluralizeString(
      this.unit.longName,
      this.length,
      true,
    );
    if (this.name) {
      return `${this.name} (${longDescription})`;
    }
    return longDescription;
  }

  #validateComparisonArguments(otherDistance, tolerance) {
    if (arguments.length === 0 || !(otherDistance instanceof Distance)) {
      throw new TypeError(`${otherDistance} must be an instance of Distance`);
    }
    if (typeof tolerance !== 'number') {
      throw new TypeError(`${tolerance} must be a number`);
    }
  }

  isLongerThan(otherDistance, tolerance = 2) {
    this.#validateComparisonArguments(otherDistance, tolerance);
    return this.toMeters(tolerance) > otherDistance.toMeters(tolerance);
  }

  isShorterThan(otherDistance, tolerance = 2) {
    this.#validateComparisonArguments(otherDistance, tolerance);
    return this.toMeters(tolerance) < otherDistance.toMeters(tolerance);
  }

  isSameLengthAs(otherDistance, tolerance = 2) {
    this.#validateComparisonArguments(otherDistance, tolerance);
    return this.toMeters(tolerance) === otherDistance.toMeters(tolerance);
  }

  times(multiplier) {
    return new Distance(this.length * multiplier, this.unit);
  }
}

export default Distance;
