/**
 *
 * @param {string} baseString The string to potentially pluralize
 * @param {number} qty How many of baseString do we have?
 * @param {boolean} [returnNumber=false] Should the number itself be returned with the pluralized string?
 * @param {string} [pluralizedString] Optional plural version of baseString
 */
export function pluralizeString(
  baseString,
  qty,
  returnNumber = false,
  pluralizedString = `${baseString}s`,
) {
  let finalString = qty === 1 ? baseString : pluralizedString;
  if (returnNumber) {
    finalString = `${qty} ${finalString}`;
  }
  return finalString;
}
