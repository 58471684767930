const DISTANCE_UNIT = Object.freeze({
  METER: {
    enum: 'METER',
    meters: 1,
    shortName: 'm',
    longName: 'meter',
    spellings: [
      'meter',
      'metre',
      'meters',
      'metres',
      'm',
      'METER',
      'METRE',
      'METERS',
      'METRES',
    ],
  },
  LAP: {
    enum: 'LAP',
    meters: 400,
    shortName: 'l',
    longName: 'lap',
    spellings: ['l', 'lap', 'laps', 'LAP', 'LAPS'],
  },
  KILOMETER: {
    enum: 'KILOMETER',
    meters: 1000,
    shortName: 'km',
    longName: 'kilometer',
    spellings: [
      'kilometer',
      'kilometers',
      'km',
      'k',
      'KILOMETER',
      'KILOMETERS',
      'KM',
      'K',
    ],
  },
  MILE: {
    enum: 'MILE',
    meters: 1609.34,
    shortName: 'mi',
    longName: 'mile',
    spellings: ['mi', 'MI', 'mile', 'miles', 'M', 'MILE', 'MILES'],
  },
  YARD: {
    enum: 'YARD',
    meters: 0.9144,
    shortName: 'yd',
    longName: 'yard',
    spellings: [
      'yard',
      'yd',
      'yards',
      'yds',
      'y',
      'YARD',
      'YD',
      'YARDS',
      'YDS',
      'Y',
    ],
  },
});

export default DISTANCE_UNIT;
