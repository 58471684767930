import Race from 'lydiard/models/Race'
import RACE_TYPE from 'lydiard/models/RACE_TYPE'
import Distance from 'lydiard/models/Distance'

const races = [
  new Race({ name: '800m', distance: Distance.fromString('800m') }),
  new Race({ name: '1500m', distance: Distance.fromString('1500m') }),
  new Race({ name: '1600m', distance: Distance.fromString('1600m') }),
  new Race({ name: '1 Mile', distance: Distance.fromString('1 mile') }),
  new Race({ name: '3000m', distance: Distance.fromString('3000m') }),
  new Race({ name: '3200m', distance: Distance.fromString('3200m') }),
  new Race({ name: '2 Miles', distance: Distance.fromString('2 miles') }),
  new Race({ name: '5000m', distance: Distance.fromString('5000m') }),
  new Race({ name: '8000m', distance: Distance.fromString('8000m') }),
  new Race({ name: '10,000m', distance: Distance.fromString('10k') }),
  new Race({
    name: 'Half Marathon',
    distance: Distance.fromString('21.0975km'),
    type: RACE_TYPE.ROAD,
  }),
  new Race({
    name: 'Marathon',
    distance: Distance.fromString('42.195km'),
    type: RACE_TYPE.ROAD,
    landmarks: [{ name: 'Half Marathon', distance: '21.0975km' }],
  }),
]

export default races
