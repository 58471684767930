function ChevronLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
    </svg>
  )
}

function ChevronRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
    </svg>
  )
}

function PredictedFinishButton({ split, race, onClick, focused }) {
  return (
    <button
      className="flex w-full items-center justify-evenly rounded-md border-2 border-transparent bg-slate-100 py-4 px-2 text-lg text-slate-700 transition-all  hover:border-slate-500 hover:bg-transparent"
      onClick={onClick}
    >
      {focused ? <ChevronLeft /> : null}
      <span className="mx-2 grow text-left font-bold">
        {race.name} <span className="font-normal">in</span>{' '}
        {split.predictFinish(race.distance)}
      </span>
      {focused ? null : <ChevronRight />}
    </button>
  )
}

export default PredictedFinishButton
