import {
  formattedDurationFromSeconds,
  formattedDurationToSeconds,
  parseDurationFromString,
} from '../utilities/duration-utilities';
import Distance from './Distance';
import DISTANCE_UNIT from './DISTANCE_UNIT';
import DURATION_FORMAT from './DURATION_FORMAT';

class Split {
  /**
   * A Split represents the time it took to run a given distance.
   *
   * @param {Object} arg
   * @param {Distance|string} arg.distance The distance of the Split. Can
   * either be a Distance instance or a string that will be parsed into a
   * Distance instance
   * @param {number} arg.seconds The time of the Split in seconds
   */
  constructor({ distance, seconds }) {
    if (!arguments.length) {
      throw new TypeError('argument required');
    }
    if (!distance || !Distance.isValidDistance(distance)) {
      throw new TypeError('valid Distance required');
    }
    if (!seconds || typeof seconds !== 'number') {
      throw new TypeError('seconds should be a number');
    }
    if (typeof distance === 'string') {
      this.distance = Distance.fromString(distance);
    } else {
      this.distance = distance;
    }
    this.seconds = seconds;
  }

  static #filterOutDuplicateDistances(distance, index, self) {
    return (
      index === self.findIndex((el) => el.toString() === distance.toString())
    );
  }

  static #sortDistancesArray(distanceA, distanceB) {
    return distanceA.isShorterThan(distanceB) ? -1 : 1;
  }

  /**
   * Static method that attempts to parse a string and turn it into an instance
   * of a Split object
   *
   * @param {string} splitString String to parse into a Split instance
   * @returns {Split}
   */
  static fromString(splitString) {
    if (arguments.length === 0 || typeof splitString !== 'string') {
      throw new TypeError(`${splitString} must be a string`);
    }

    // First try to find the distance string
    // Then remove the distance string from the input
    // And attempt to find the duration in the remaining string

    // This will throw an error if it can't find a distance
    const distance = Distance.fromString(splitString);
    const distanceString = Distance.parseDistanceStringFromString(splitString);
    const splitStringWithoutDistance = splitString.replace(distanceString, '');
    // This will throw an error if it can't find a duration
    const duration = parseDurationFromString(splitStringWithoutDistance, false);

    return new Split({
      distance,
      seconds: formattedDurationToSeconds(duration),
    });
  }

  /**
   * Checks if the arg is either a valid Split instance or is a string that can
   * be parsed into a valid Split instance.
   *
   * @param {string|Split} arg The thing to test
   * @returns boolean
   */
  static isValidSplit(arg) {
    if (!arguments.length) {
      throw TypeError('pass a string or Split instance to isValidSplit');
    }
    if (typeof arg === 'string') {
      try {
        Split.fromString(arg);
        return true;
      } catch (e) {
        return false;
      }
    }
    if (arg?.constructor === Split) {
      return true;
    }
    return false;
  }

  /**
   * Returns the pace of this Split instance as m/s.
   * Meant for calculation purposes.
   *
   * @param {number} [precision=2] How many decimal places to use
   * @returns {number} ex: 5.56 (as m/s)
   * @memberof Split
   */
  metersPerSecond(precision = 2) {
    return parseFloat(
      (this.distance.toMeters() / this.seconds).toFixed(precision),
    );
  }

  /**
   * Returns the pace of this Split instance as s/m.
   * Meant for calculation purposes.
   *
   * @param {number} [precision=4] How many decimal places to use?
   * @returns {number} ex: 0.1491 (as s/m)
   * @memberof Split
   */
  secondsPerMeter(precision = 4) {
    return parseFloat(
      (this.seconds / this.distance.toMeters()).toFixed(precision),
    );
  }

  /**
   * Returns the pace of this Split instance as s/M.
   * Meant for calculation purposes.
   *
   * @param {number} [precision=4] How many decimal places to use?
   * @returns {number} ex: 314.1591 (as s/M)
   * @memberof Split
   */
  secondsPerMile(precision = 4) {
    return parseFloat(
      (this.seconds / this.distance.toMiles(4)).toFixed(precision),
    );
  }

  /**
   *
   * @param {number} [precision=4]
   */
  secondsPerNativeUnit(precision = 4) {
    return parseFloat((this.seconds / this.distance.length).toFixed(precision));
  }

  /**
   * Returns the pace of this Split instance per mile.
   * Meant for display purposes.
   *
   * @returns {string} ex: 4:01.5/mile
   * @memberof Split
   */
  pacePerMile() {
    const oneMile = Distance.fromString('1M');
    const mileInSplitUnits = oneMile.convertTo(this.distance.unit);
    const secondsPerSplitUnit = this.secondsPerNativeUnit(8);
    const secondsPerMile = parseFloat(
      (mileInSplitUnits * secondsPerSplitUnit).toFixed(1),
    );
    return `${formattedDurationFromSeconds(secondsPerMile, {
      format: DURATION_FORMAT.MINUTES,
      precision: 1,
    })}/mile`;
  }

  pacePer400m() {
    return `${formattedDurationFromSeconds(this.secondsPerMeter() * 400, {
      format: DURATION_FORMAT.LAP,
      precision: 1,
    })}/400m`;
  }

  pacePerLap() {
    return `${formattedDurationFromSeconds(this.secondsPerMeter() * 400, {
      format: DURATION_FORMAT.LAP,
      precision: 1,
    })}/lap`;
  }

  /**
   * Returns the pace of this Split instance per km. Meant for display purposes.
   *
   * @returns {string} ex: 2:37.2/km
   * @memberof Split
   */
  pacePerKilometer() {
    const oneKilometer = Distance.fromString('1km');
    const kmInSplitUnits = oneKilometer.convertTo(this.distance.unit);
    const secondsPerSplitUnit = this.secondsPerNativeUnit(8);
    const secondsPerKM = parseFloat(
      (kmInSplitUnits * secondsPerSplitUnit).toFixed(1),
    );
    return `${formattedDurationFromSeconds(secondsPerKM, {
      format: DURATION_FORMAT.MINUTES,
      precision: 1,
    })}/km`;
  }

  #isLongerThanSplit(split) {
    return this.distance.isLongerThan(split.distance);
  }

  #isLongerThanDistance(distance) {
    return this.distance.isLongerThan(distance);
  }

  /**
   * Helper to see if this Split instance is longer/farther than another Split
   * or Distance instance
   *
   * @param {Split|Distance|string} otherSplitOrDistance - The split or distance
   * to compare this instance's distance to
   * @returns {boolean} `true` if this instance's distance is longer than the
   * distance of the `otherSplitOrDistance`
   */
  isLongerThan(otherSplitOrDistance) {
    const errorMessage =
      'pass a Split, Distance, or string that can be parsed as a Distance to `Split.isLongerThan()`';
    if (arguments.length === 0 || !otherSplitOrDistance) {
      throw new TypeError(errorMessage);
    }
    if (otherSplitOrDistance.constructor === Split) {
      return this.#isLongerThanSplit(otherSplitOrDistance);
    }
    if (otherSplitOrDistance.constructor === Distance) {
      return this.#isLongerThanDistance(otherSplitOrDistance);
    }
    if (typeof otherSplitOrDistance !== 'string') {
      throw new TypeError(errorMessage);
    }
    let distance = null;
    try {
      distance = Distance.fromString(otherSplitOrDistance);
      return this.#isLongerThanDistance(distance);
    } catch (error) {
      throw new TypeError(errorMessage);
    }
  }

  predictSecondsForDistance(distance) {
    let seconds;
    if (this.distance.unit === distance.unit) {
      seconds = this.secondsPerNativeUnit() * distance.length;
    } else if (distance.unit === DISTANCE_UNIT.MILE) {
      seconds = this.secondsPerMile() * distance.length;
    } else if (distance.unit === DISTANCE_UNIT.YARD) {
      seconds = this.secondsPerMile() * (distance.length / 1760);
    } else {
      seconds = this.secondsPerMeter() * distance.toMeters();
    }
    return seconds;
  }

  getIntermediateSplits(intervals, landmarks = []) {
    const splitDistances = [];
    intervals?.forEach((interval) => {
      const intervalDistance = Distance.fromString(interval);
      let counter = 1;
      let distance = intervalDistance.times(counter);
      while (distance.isShorterThan(this.distance)) {
        splitDistances.push(distance);
        counter++;
        distance = intervalDistance.times(counter);
      }
    });
    landmarks.forEach((landmark) => {
      const distance = Distance.fromString(landmark.distance);
      distance.name = landmark.name;
      splitDistances.push(distance);
    });
    const sortedUniqueDistances = splitDistances
      .filter(Split.#filterOutDuplicateDistances)
      .sort(Split.#sortDistancesArray);

    return sortedUniqueDistances.reduce((splits, distance) => {
      const seconds = this.predictSecondsForDistance(distance);
      splits.push(new Split({ distance, seconds }));
      return splits;
    }, []);
  }

  /**
   * Returns a string representation of this Split instance
   *
   * @param {object} [{ precision = 1, format = DURATION_FORMAT.MINUTES }={}]
   * @returns {string} ex: '400m in 0:59.5'
   * @memberof Split
   */
  toString({
    precision = 1,
    format = DURATION_FORMAT.MINUTES,
    shortDistanceFormat = true,
  } = {}) {
    return `${this.distance.toString(
      shortDistanceFormat,
    )} in ${formattedDurationFromSeconds(this.seconds, {
      precision,
      format,
    })}`;
  }

  predictFinish(distance) {
    const seconds = this.predictSecondsForDistance(distance);
    return formattedDurationFromSeconds(seconds, {
      format: DURATION_FORMAT.MINUTES,
      precision: 1,
    });
  }
}

export default Split;
